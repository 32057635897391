import React, { createContext, useEffect, useState } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { app } from "./firebase";
const ImageContext = createContext();

export const ImageProvider = ({ children }) => {

  const fetchImagesFromDirectory = async (directoryPath) => {
    try {
      const storage = getStorage(app);
      const listRef = ref(storage, directoryPath);
      const res = await listAll(listRef);
      const imageObjects = await Promise.all(res.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        const name = itemRef.name; // Get the name of the image
        return { name, url };
      }));
  
      // Create a lookup object
      const imageLookup = imageObjects.reduce((acc, { name, url }) => {
        acc[name] = url;
        return acc;
      }, {});
      
      return imageLookup;
    } catch (error) {
      console.error(`Error fetching images from ${directoryPath}:`, error);
      return {};
    }
  };
  
  const [UserImages, setUserImages] = useState({});
  // console.log(UserImages)
  
  useEffect(() => {
    const fetchAllImages = async () => {
      try {
        const [UserAppImages] = await Promise.all([
          fetchImagesFromDirectory('User-App'),
        ]);
  
        setUserImages(UserAppImages);
      } catch (error) {
        console.error('Error fetching all images:', error);
      }
    };
  
    fetchAllImages();
  }, []);
  

  return (
    <ImageContext.Provider
      value={{
        UserImages,
   
      }}
    >
      {children}
    </ImageContext.Provider>
  );
};

export default ImageContext;
