/** @format */

import axios from 'axios';
import { getCookie } from 'typescript-cookie';

const baseURL = process.env.REACT_APP_API_URL_USER_SERVER;

const AxiosInstanceUserServer = axios.create({
  baseURL: baseURL,
  // timeout: 5000,
  headers: {
    Authorization: getCookie('jwt_token') ? getCookie('jwt_token') : null,
    'Content-Type': 'application/x-www-form-urlencoded',
    accept: 'application/json',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    },
    withCredentials: true,
  },
});

AxiosInstanceUserServer.interceptors.response.use(
  (response) => {
    return response;
  },

  async function (error) {
    if (typeof error.response === 'undefined') {
      alert(
        'A server/network error occurred. Looks like CORS might be the problem. Sorry about this - we will get it fixed shortly.'
      );
      return Promise.reject(error);
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);
export default AxiosInstanceUserServer;
