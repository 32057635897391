/** @format */

import React, { useContext, useState } from 'react';
import { Row, Button, Spinner } from 'react-bootstrap';
import { removeCookie } from 'typescript-cookie';

import sad from '../images/sad.png';
import AxiosInstanceUserServer from '../components/axios/AxiosInstanceUserServer';
import ImageContext from '../ImageContext';

export default function RejectedPage() {
  // Loading in Button
  const [isLoading, setLoading] = useState(false);
  const {UserImages} = useContext(ImageContext)

  function handleLogout() {
    setLoading(true);
    // Send Request to Backend to Add the Token to the token_block_list Table
    AxiosInstanceUserServer.delete('/account/logout').then((res) => {
      if (res.data === 'Token Expired') {
        // Delete the Token from Cookies
        removeCookie('jwt_token');
        // Redirect the User to the Login App
        window.location.replace(
          `${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}`
        );
      }
    });
  }

  return (
    <>
      <Row
        style={{
          justifyContent: 'center',
          display: 'flex',
          margin: '0',
          padding: '0 0 0 0',
          marginTop: '20px',
        }}>
        <Row style={{ width: '30%', margin: '0', padding: '0 0 0 0' }}>
          <img src={UserImages['sad.png']} alt='coming soon' />
        </Row>
        <Row
          style={{
            textAlign: 'center',
            margin: '0',
            padding: '0 0 0 0',
            marginTop: '5px',
            justifyContent: 'center',
            display: 'flex',
          }}>
          <h2 style={{ fontWeight: 'bold' }}>
            {' '}
            Sorry! Your application was denied!
          </h2>
          {/* <p style={{ width: '400px', marginTop: '10px', color: '#565662' }}>
          
        </p> */}

          <Row
            style={{
              padding: '0 0 0 0',
              marginTop: '15px',
              justifyContent: 'center',
              display: 'flex',
              marginBottom: '100px',
            }}>
            <Button
              variant='secondary'
              id='logout-button'
              style={{
                boxShadow: '0 2px 4px rgb(50 50 93 / 10%)',
                width: '10%',
              }}
              onClick={!isLoading ? handleLogout : null}>
              {isLoading ? (
                <>
                  Loading…{' '}
                  <Spinner animation='border' variant='light' size='sm' />
                </>
              ) : (
                'Logout'
              )}
            </Button>
          </Row>
        </Row>
      </Row>
    </>
  );
}
