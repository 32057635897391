/** @format */

import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CryptoJS from 'crypto-js';

import LoginRedirection from '../components/LoginRedirection';

const CreateAccountPaper = React.lazy(() => {
  return Promise.all([
    import('../components/CreateAccountPaper'),
    new Promise((resolve) => setTimeout(resolve, 400)),
  ]).then(([moduleExports]) => moduleExports);
});

function CreateAccount(props) {
  useEffect(() => {
    clearCacheData();
  }, []);
  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  // Crypto_JS to encrypt & decrypt data

  const location = window.location.search;
  const searchParams = new URLSearchParams(location);
  let accountIdQuery = searchParams.get('name');

  const [text, setText] = useState(accountIdQuery);
  const [screen, setScreen] = useState('encrypt');

  const [encrptedData, setEncrptedData] = useState('');
  const [decrptedData, setDecrptedData] = useState('');

  const secretPass = `${process.env.REACT_APP_REFFERAL_SECRET_PASS}`;

  const encryptData = (e1) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(e1),
      secretPass
    ).toString();

    setEncrptedData(data);
  };

  const decryptData = (e1) => {
    const bytes = CryptoJS.AES.decrypt(e1, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setDecrptedData(data);
  };

  useEffect(() => {
    // encryptData(text);
    if (accountIdQuery)
      try {
        decryptData(text);
      } catch (err) {}
  }, []);

  return (
    <>
      {props.tokenData.status === 'token is valid' ? (
        <LoginRedirection tokenData={props.tokenData} />
      ) : (
        <SkeletonTheme baseColor='#ebedf6ec'>
          <Row
            lg={2}
            className='back_ben'
            style={{
              margin: 'auto',
              padding: '10px',
              marginTop: '30px',
              width: '80%',
              justifyContent: 'center',
              display: 'flex',
            }}>
            <React.Suspense
              fallback={
                <Row style={{ justifyContent: 'center', display: 'flex' }}>
                  <Row
                    style={{
                      width: '100%',
                      margin: '0',
                      padding: '0 0 0 0',
                      marginTop: '80px',
                    }}>
                    <Col
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      style={{
                        margin: 'auto',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                      }}>
                      <Skeleton
                        height='20px'
                        width='90%'
                        style={{ marginBottom: '10px' }}
                      />
                      <Skeleton
                        height='20px'
                        width='90%'
                        style={{ marginBottom: '10px' }}
                      />
                      <Skeleton
                        height='20px'
                        width='90%'
                        style={{ marginBottom: '10px' }}
                      />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} style={{}}>
                      <Skeleton height='400px' width='90%' />
                    </Col>
                  </Row>
                </Row>
              }>
              <CreateAccountPaper
                decrptedData={decrptedData}
                accountIdQuery={accountIdQuery}
              />
            </React.Suspense>
          </Row>
        </SkeletonTheme>
      )}
    </>
  );
}
export default React.memo(CreateAccount);
