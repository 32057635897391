/** @format */

import 'react-loading-skeleton/dist/skeleton.css';

import React, { useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import LoginRedirection from '../components/LoginRedirection';

const LoginPaper = React.lazy(() => {
  return Promise.all([
    import('../components/LoginPaper'),
    new Promise((resolve) => setTimeout(resolve, 400)),
  ]).then(([moduleExports]) => moduleExports);
});

function Login(props) {
  // Function to clear complete cache data
  useEffect(() => {
    clearCacheData();
    if (window.location.pathname === '/') {
      window.location.replace('/login');
    }
  }, []);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };


  return (
    <>
      {props.tokenData.status === 'token is valid' ? (
        <LoginRedirection tokenData={props.tokenData} />
      ) : (
        <SkeletonTheme baseColor='#ebedf6ec'>
          <Row
            lg={2}
            md={1}
            sm={1}
            xs={1}
            className='back_ben'
            style={{
              margin: 'auto',
              padding: '10px',
              marginTop: '30px',
              width: '80%',
              justifyContent: 'center',
              display: 'flex',
            }}>
            <React.Suspense
              fallback={
                <Row style={{ justifyContent: 'center', display: 'flex' }}>
                  <Row
                    style={{
                      width: '100%',
                      margin: '0',
                      padding: '0 0 0 0',
                      marginTop: '80px',
                    }}>
                    <Col
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      style={{
                        margin: 'auto',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                      }}>
                      <Skeleton
                        height='20px'
                        width='90%'
                        style={{ marginBottom: '10px' }}
                      />
                      <Skeleton
                        height='20px'
                        width='90%'
                        style={{ marginBottom: '10px' }}
                      />
                      <Skeleton
                        height='20px'
                        width='90%'
                        style={{ marginBottom: '10px' }}
                      />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} style={{}}>
                      <Skeleton height='400px' width='90%' />
                    </Col>
                  </Row>
                </Row>
              }>
              <LoginPaper />
            </React.Suspense>
          </Row>
        </SkeletonTheme>
      )}
    </>
  );
}
export default React.memo(Login);
