/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';

import AxiosInstanceUserServer from './components/axios/AxiosInstanceUserServer';
import Login from './main-pages/Login';
import CreateAccount from './main-pages/CreateAccount';
import BlockedPage from './main-pages/BlockedPage';
import NotFound from './components/NotFound';
import Crypto_JS from './main-pages/Crypto_JS';
import RejectedPage from './main-pages/RejectedPage';
import UnderMaintenance from './main-pages/UnderMaintenance';
import ImageContext from './ImageContext';

function App() {
  
  // Function to clear complete cache data
  const [loading, setLoading] = useState(true);
  const {UserImages} = useContext(ImageContext)
  useEffect(() => {
    clearCacheData();
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [loading]);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  // Get the Owner & Merchants Data from the Token
  const [tokenData, setTokenData] = useState({
    status: '',
    owner: '',
    merchants: [''],
  });
  useEffect(() => {
    getTokenData();
  }, []);
  function getTokenData() {
    AxiosInstanceUserServer.get('/account/token').then((res) => {
      setTokenData(res.data);
    });
  }

  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'kvjrdr67',
  });
  // window.Intercom("update");

  return (
    <>

      {loading ? (
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
            height: '100hv',
            marginTop: '330px',
          }}>
          <BeatLoader color='#304DAF' size='20px' />
        </div>
      ) : (
        <Router>
          <ToastContainer hideProgressBar={true} newestOnTop={true} />

          <Row
            style={{
              justifyContent: 'center',
              display: 'flex',
              margin: '0',
              padding: '0 0 0 0',
              marginTop: '60px',
            }}>
            <Col
              lg={5}
              md={5}
              sm={5}
              xs={5}
              style={{
                justifyContent: 'right',
                display: 'flex',
                margin: '0',
                padding: '0 0 0 0',
                paddingRight: '5px',
              }}>
              <a
                href={`${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}/`}>
                <img
                  alt='Fintesa Icon'
                  src={UserImages['FintesaIcon.png']}
                  style={
                    window.innerWidth >= 576
                      ? { width: '50px', height: '50px' }
                      : { width: '45px', height: '45px' }
                  }
                />
              </a>
            </Col>

            <Col
              lg={5}
              md={6}
              sm={6}
              xs={6}
              style={{
                zIndex: '1',
                justifyContent: 'left',
                display: 'flex',
                margin: '0',
                padding: '0 0 0 0',
                paddingLeft: '5px',
              }}>
              <a
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  textAlign: 'center',
                  FontSize: '14px',
                  zIndex: '2',
                }}
                href={`${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}/`}>
                <p
                  style={
                    window.innerWidth >= 576
                      ? { fontSize: '32px' }
                      : { fontSize: '26px', fontWeight: '700' }
                  }>
                  Fintesa
                </p>
              </a>
            </Col>
          </Row>

          <img
            alt='Back_Benefit_M2'
            src={UserImages['Back_Benefit_M2.png']}
            style={
              window.innerWidth > 768
                ? {
                    position: 'absolute',
                    top: '0px',
                    padding: '0 0 0 0',
                    margin: '0',
                    width: '100%',
                    zIndex: '-1',
                    marginTop: '-150px',
                  }
                : {
                    position: 'absolute',
                    top: '0px',
                    padding: '0 0 0 0',
                    margin: '0',
                    width: '100%',
                    zIndex: '-1',
                  }
            }
          />

          <Container
            id='top'
            fluid
            style={{
              margin: '0',
              padding: '0',
            }}>
            <Routes>
              {/* Login Page */}
              <Route path='/' element={<Login tokenData={tokenData} />}></Route>
              <Route
                path='/login'
                element={<Login tokenData={tokenData} />}></Route>
              {/* Create Account Page */}
              <Route
                path='/create-account'
                element={<CreateAccount tokenData={tokenData} />}></Route>

              <Route
                path='/crypto'
                element={<Crypto_JS tokenData={tokenData} />}></Route>

              {/* Blocked Page */}
              <Route path='/blocked' element={<BlockedPage />}></Route>

              {/*Rejected Page*/}
              <Route path='/rejected' element={<RejectedPage />}></Route>

              {/* 404 Page */}
              <Route path='*' element={<NotFound />}></Route>
            </Routes>
          </Container>
        </Router>
      )}
    </>

    //   <UnderMaintenance />
  );
}

export default App;
