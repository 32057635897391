/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

function NotFound() {
  useEffect(() => {
    clearCacheData();
  }, []);

  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate('/login');
    }, 5000);
  }, []);

  return (
    <Row
      style={{
        justifyContent: 'center',
        textAlign: 'center',
        marginBottom: '20%',
        marginTop: '10%',
      }}>
      <Row>
        <h1 style={{ marginBottom: '3%' }}>404 Page Not Found</h1>
        <h5 style={{ marginBottom: '3%' }}>
          You will be redirected to the Login page after 5 seconds
        </h5>
        <Spinner
          animation='border'
          role='status'
          variant='primary'
          style={{ marginLeft: '50%', marginBottom: '3%' }}>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </Row>

      <Row>
        <p>
          If your browser doesn't work, <Link to='/login'>Click here</Link> to
          go to the Login page
        </p>
      </Row>
    </Row>
  );
}
export default React.memo(NotFound);
