/** @format */

import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { removeCookie } from 'typescript-cookie';

import AxiosInstanceUserServer from './axios/AxiosInstanceUserServer';

function LoginRedirection(props) {
  // Send Request to Backend to Add the Token to the token_block_list Table
  function handleLogout() {
    AxiosInstanceUserServer.delete('/account/logout').then((res) => {
      if (res.data === 'Token Expired') {
        // Delete the Token from Cookies
        removeCookie('jwt_token');
        // Redirect the User to the Same page
        window.location.replace(`/login`);
      }
    });
  }
  function handleGoToAccount() {
    window.location.replace(
      `${process.env.REACT_APP_FRONTEND_URL_MENACART}/dashboard`
    );
  }

  return (
    <>
      <Row
        style={
          window.innerWidth > 992
            ? {
                justifyContent: 'center',
                display: 'flex',
                margin: '0',
                padding: '0 0 0 0',
                marginTop: '100px',
                zIndex: '2',
              }
            : {
                justifyContent: 'center',
                display: 'flex',
                margin: '0',
                padding: '0 0 0 0',
                zIndex: '2',
              }
        }>
        <Card
          border='light'
          className='shadow p-3 mb-5 bg-white rounded'
          style={
            window.innerWidth >= 768
              ? {
                  width: '40%',
                  height: '100%',
                  borderRadius: '12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.644)',
                  margin: '10px',
                  marginTop: '0',
                }
              : {
                  width: '100%',
                  borderRadius: '12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.644)',
                  // margin: '10px',
                  // paddingTop: '50px',
                }
          }>
          <Row
            style={{
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
              marginTop: '30px',
            }}>
            <p style={{ fontWeight: 'bold' }}>
              You Are Already Logged in as {props.tokenData.owner.email}
            </p>
            {/* List of Merchants */}

            {/* Logout Button */}
            <Row
              style={{
                marginTop: '30px',
                marginBottom: '50px',
                justifyContent: 'center',
                display: 'flex',
              }}>
              <Col>
                <Button
                  variant='outline-secondary'
                  style={{
                    width: '250px',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                  onClick={handleLogout}>
                  Login to a different account
                </Button>
              </Col>
              <Col>
                <Button
                  variant='secondary'
                  style={{
                    width: '250px',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                  onClick={handleGoToAccount}>
                  Go to your dashboard
                </Button>
              </Col>
            </Row>
          </Row>
        </Card>
      </Row>
    </>
  );
}

export default LoginRedirection;
