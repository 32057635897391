/** @format */

import React from 'react';
import { Row } from 'react-bootstrap';

export default function Blocked() {
  return (
    <>
      <Row
        style={{
          width: '100%',
          margin: '0',
          textAlign: 'center',
          padding: '0',
        }}>
        <h3
          style={{
            marginTop: '100px',
            marginBottom: '100px',
            fontWeight: 'bold',
          }}>
          Your Email has been restricted from using our platforms.<br></br>
          This is because you might used multiple businesses with one merchant
          account, violated any rules and conditions. or you used{' '}
          <a
            href='https://fintesa.co/pending-verification'
            target='_blank'
            rel='noopener noreferrer'
            style={{ fontSize: '18px' }}>
            illegal activity
          </a>{' '}
          !
        </h3>
      </Row>
    </>
  );
}
